@charset "utf-8";
.l-main{
    position: relative;
    @include sp {
        overflow-x: hidden;
    }
}

.l-mainUnder {
    position: relative;
    @include pc {
        padding-top: 100px;
        padding-bottom: 200px;
    }
    @include sp {
        padding-top: 80px;
        padding-bottom: 100px;
    }
}