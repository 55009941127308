@charset "utf-8";
.main_bg {
    // background: olive;
    height: 740px;
    position: fixed;
    width: 100%;

    // z-index: 1;
    @include sp {
        // background: olive;
        height: 100vh;
    }
}
.main_bgInner {
    height: 100%;
    margin: 0 auto;
    position: relative;
    width: 1234px;
    @include sp {
        height: 100vh;
        width: 100%;
    }
}
.main_bg_ttl {
    left: 0;
    opacity: 0;
    position: absolute;
    top: 50%;
    // transition: width 2s, height 2s, background-color 2s, transform 2s;
    @include sp {
        // display: none;
    }
    &01 {
        margin: -160px 0 0;
        // transform: translateX(-140px) scaleX(1.05);
        height: 352px;
        left: -200px;
        opacity: 0;
        transition: left 1s, width 1s, opacity 1s;
        width: 1142px;
        @include sp {
            height: auto;
            left: -100px;
            margin: -20vw 0 0;
            // transform: translateX(-80px) scaleX(1.05);
            width: 110%;
        }
        &.isActive {
            left: 0px;
            opacity: 1;
            width: 1042px;
            @include sp {
                width: 100%;
            }
        }
    }
    &02 {
        margin: -62px 0 0;
        // transform: translateX(-140px) scaleX(1.05);
        height: 133px;
        left: -200px;
        opacity: 0;
        transition: left 1s, width 1s, opacity 1s;
        width: 859px;
        @include sp {
            height: auto;
            left: -100px;
            margin: -20vw 0 0;
            width: 110%;
        }
        &.isActive {
            left: 0px;
            opacity: 1;
            width: 759px;
            @include sp {
                width: 100%;
            }
        }
    }
}

.p-top {

}

.p-top__KvMovie {
    width: 100%;
    video {
        border: none;
        width: 100%;
        @include sp {
            height: 134vw;
        }
    }
}



// ********** .p-top_read **********
.p-top_read {
    background: linear-gradient(180deg, rgba(255, 255, 255, 100), transparent);
    @include sp {
        background: linear-gradient(180deg, rgba(255, 255, 255, 100) 70%, rgba(255, 255, 255, 0));
    }
}
.p-top_readInner {
    background: url(/assets/img/read_bg.png) repeat-x;
    background-position: 0 200px;
    animation: bgroop 30s linear infinite;
    padding: 120px 0 252px;
    @include sp {
        background-position: 0 57vw;
        background-size: 586vw;
    }
}
@include pc {
    @-webkit-keyframes bgroop {
        from {background-position: 0 200px;}
        to {background-position: -1300px 200px;}
    }
    @keyframes bgroop {
        from {background-position: 0 200px;}
        to {background-position: -1300px 200px;}
    }
}
@include sp {
    @-webkit-keyframes bgroop {
        from {background-position: 0 57vw;}
        to {background-position: -293vw 57vw;}
    }
    @keyframes bgroop {
        from {background-position: 0 57vw;}
        to {background-position: -293vw 57vw;}
    }
}
.p-top_readText {
    font-size: 18px;
    line-height: 55/18*1;
    text-align: center;
    @include sp {
        @include vw(30);
    }
}



// ********** .p-topAbout__cont **********
.p-topAbout {
    padding: 0 0 298px;
    @include sp {
        padding: 0 0 160/750*100vw;
    }
}
.p-topAbout__cont {
    background: url(/assets/img/about_bg.png) no-repeat left 150px;
    margin: 10px auto;
    padding: 100px 0 0 417px;
    position: relative;
    width: 1100px;
    @include sp {
        background-position: left 22vw;
        background-size: 70%;
        margin: 0 auto;
        padding: 400/750*100vw 0 0 0;
        width: 100%;
    }
}
.p-topAbout__listItem {
    display: flex;
    &+.p-topAbout__listItem {
        margin: 26px 0 0;
    }
    div {
        @include sp {
            width: 480/750*100vw;
            @include vw(28);
        }
    }
    p {
        width: 144px;
        @include sp {
            width: 144/750*100vw;
            @include vw(28);
        }
    }
}
.p-topAbout_ttl {
    opacity: 0;
    @include sp {
        opacity: 1;
    }
}



// ********** .p-topContact **********
.p-topContact {
    background: #000;
    color: #fff;
    height: 365px;
    padding: 100px 0 0;
    text-align: center;
    @include sp {
        height: auto;
        padding: 160/750*100vw 0 140/750*100vw;
    }
    a {
        display: inline-block;
        color: #fff;
        font-size: 22px;
        padding: 54px 0 0;
    }
    p {
        padding: 34px 0 0;
    }
}



// ********** .p-topLink **********
.p-topLink {
    height: 384px;
    padding: 70px 0 0;
    @include sp {
        height: auto;
        padding: 120/750*100vw 0 0;
    }
}
.p-topLink__box {
    display: flex;
    justify-content: center;
    margin: -30px 0 0;
    @include sp {
        display: block;
        margin: 37/750*100vw auto 0;
        width: 535/750*100vw;
    }
    a {
        display: inline-block;
        &+a {
            margin: 0 15px;
            @include sp {
                margin: 72/750*100vw 0 0;
            }
        }
    }
}
.p-topLink_ttl {
    @include sp {
        width: 273/750*100vw;
    }
}



// ********** .p-topMember **********
.p-topMember {
    // margin: 0 0 324px;
    padding: 0 0 324px;
    @include sp {
        padding: 0 0 260/750*100vw;
    }
}
.p-topMember__box {
    display: flex;
    padding: 0 0 0 28px;
    position: relative;
    @include sp {
        display: block;
        padding: 0;
    }
    &+.p-topMember__box {
        margin: 74px 0 0;
        @include sp {
            margin: 0 0 0;
        }
    }
    &--02 {
        flex-direction: row-reverse;
        padding: 0 28px 0 0;
        @include sp {
            padding: 196/750*100vw 0 0;
        }
    }
}
.p-topMember__boxCont {
    padding: 52px 0 0 70px;
    @include sp {
        padding: 0;
    }
    .p-topMember__box--02 & {
        padding: 52px 70px 0 0;
        @include sp {
            padding: 0;
        }
    }
}
.p-topMember__boxContText {
    font-size: 15px;
    line-height: 35/15*1;
    margin: 50px 0 0;
    width: 455px;
    @include sp {
        line-height: 64/28*1;
        margin: 56/750*100vw 0 0;
        width: 100%;
        @include vw(28);
    }
}
.p-topMember__boxContTtl {
    font-size: 32px;
    font-weight: normal;
    vertical-align: text-bottom;
    @include sp {
        margin: 84/750*100vw 0 0;
        @include vw(51);
    }
    span {
        display: inline-block;
        font-size: 16px;
        margin: 0 40px 0 0;
        vertical-align: baseline;
        @include sp {
            margin: 0 40/750*100vw 0 0;
            @include vw(30);
        }
    }
}
.p-topMember__boxName {
    left: 0;
    position: absolute;
    top: -22px;
    @include sp {
        top: -5vw;
        width: 350/750*100vw;
    }
    .p-topMember__box--02 & {
        bottom: -27px;
        left: auto;
        right: 0;
        top: auto;
        @include sp {
            bottom: auto;
            top: 101vw;
            width: 357/750*100vw;
        }
    }
}
.p-topMember__cont {
    margin: 0 auto;
    padding: 228px 0 0;
    position: relative;
    width: 1100px;
    @include sp {
        padding: 228/750*100vw 0 0;
        width: 100%;
    }
}
.p-topMember_ttl {
    opacity: 0;
    transform: translateX(-140px) scaleX(1.05);
}



// ********** .p-topNews **********
.p-topNews {
    // background-color: yellow;
    height: 824px;
    @include sp {
        height: auto;
    }
}
.p-topNews__cont {
    display: flex;
    margin: 10px 0 0;
    padding: 96px 0 0;
    position: relative;
    @include sp {
        // display: block;
        flex-wrap: wrap;
        flex-direction : column-reverse;
        padding: 96px 0 280/750*100vw;
    }
}
.p-topNews__contImgBox {
    padding: 102px 0 0;
    @include sp {
        padding: 100/750*100vw 70/750*100vw 0;
        width: 100%;
    }
}
.p-topNews__contNewsBox {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    height: 295px;
    margin: 0 0 0 56px;
    @include sp {
        height: auto;
        margin: 0;
        width: 100%;
    }
}
.p-topNews__contNewsList {
    border-bottom: solid 1px #D6D6D6;
    padding: 0 0 28px;
    width: 728px;
    @include sp {
        width: 100%;
    }
}
.p-topNews__contNewsListItem {
    &--date {
        display: flex;
        img {
            margin: 0 14px 0 0;
            @include sp {
                width: 77/750*100vw;
            }
        }
        p {
            padding: 0 26px 0 0;
            @include sp {
                @include vw(24);
            }
        }
    }
    &--text {
        margin: 5px 0 0;
        a {
            text-decoration: underline;
            @include sp {
                @include vw(28);
            }
        }
    }
}
.p-topNews_ttl {
    opacity: 0;
    @include sp {
        opacity: 1;
    }
}



// ********** .p-topWwc **********
.p-topWwc {
    // background: url(/assets/img/wwc_ttl.png) no-repeat left 150px;
    // background-attachment: fixed;
}
.p-topWwc__cont {
    margin: 0 auto;
    position: relative;
    width: 1100px;
    @include sp {
        width: 100%;
    }
    &--chef {
        padding: 488px 0 0;
        @include sp {
            padding: 20vw 0 0;
        }
    }
    &--prounit {
        @include sp {
            padding: 230/750*100vw 0 0;
        }
    }
}
.p-topWwc__contBox {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 92px 0 130px;
    position: relative;
    text-align: center;
    width: 960px;
    @include sp {
        display: block;
        width: 100%;
    }
    &--case {
        @include sp {
            // background: olive;
            height: 144vw;
            padding: 244/750*100vw 0 130px;
            position: relative;
        }
    }
}
.p-topWwc__contBox02 {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 250px 0 258px;
    position: relative;
    text-align: center;
    width: 1100px;
    @include sp {
        display: block;
        height: 240vw;
        margin: 45/750*100vw 0 0;
        padding: 47vw 0 0;
        width: 100%;
    }
}
.p-topWwc__contBoxItem {
    background: #fff;
    border: solid 1px #A8A8A8;
    padding: 89px 0 0;
    text-align: center;
    @include pc {
        border-radius: 150px;
        height: 300px;
        opacity: 0;
        transform: translateY(60px);
        width: 300px;
    }
    @include sp {
        border-radius: 171/750*100vw;
        height: 342/750*100vw;
        opacity: 0;
        padding: 77/750*100vw 0 0;
        transform: translateY(60px);
        width: 342/750*100vw;
    }
    &--01 {
        @include sp {
            left: 50%;
            margin: 0 0 0 -25%;
            position: absolute;
            top: 32vw;
        }
    }
    &--02 {
        @include sp {
            left: -2vw;
            position: absolute;
            top: 76vw;
        }
    }
    &--03 {
        @include sp {
            position: absolute;
            right: -2vw;
            top: 76vw;
        }
    }
}
.p-topWwc__contBox02Item {
    opacity: 0;
    @include pc {
        transform: translateY(60px);
    }
    &:nth-child(1) {
        @include sp {
            transform: translateX(60px);
        }
    }
    &:nth-child(2) {
        margin: 93px 0 0;
        @include sp {
            margin: 10/750*100vw 0 0;
            transform: translateX(60px);
        }
    }
    &:nth-child(3) {
        margin: 28px 0 0;
        @include sp {
            margin: 10/750*100vw 0 0;
            transform: translateX(60px);
        }
    }
    &:nth-child(4) {
        margin: 103px 0 0;
        @include sp {
            margin: 10/750*100vw 0 0;
            transform: translateX(60px);
        }
    }
    &:nth-child(5) {
        margin: 14px 0 0;
        @include sp {
            margin: 10/750*100vw 0 0;
            transform: translateX(60px);
        }
    }
}
.p-topWwc__contBoxItemImg {
    bottom: 0;
    left: 50%;
    margin: 0 0 0 -232px;
    opacity: 0;
    position: absolute;
    transform: translateY(60px);
    @include sp {
        left: 50%;
        margin: 0 0 0 -285/750*100vw;
        width: 570/750*100vw;
    }
}
.p-topWwc__contBox02ItemImg {
    left: 50%;
    margin: 0 0 0 -232px;
    position: absolute;
    opacity: 0;
    top: 0;
    @include sp {
        left: 0;
        margin: 0 0 0;
        opacity: 1;
    }
}
.p-topWwc__contBoxItemText {
    font-size: 18px;
    margin: 48px 0 0;
    @include sp {
        margin: 38/750*100vw 0 0;
        @include vw(26);
    }
}
.p-topWwc__contBoxItemTtl {
    @include sp {
        margin: 0 auto;
        width: 128/750*100vw;
    }
}
.p-topWwc__contRead {
    padding: 100px 0 0;
    @include sp {
        padding: 123/750*100vw 0 0;
    }
}
.p-topWwc__contChef {
    padding: 100px 0 168px;
    position: relative;
    @include sp {
        display: none;
    }
    &--sp {
        @include pc {
            display: none;
        }
        background: #fff;
        margin: 205/750*100vw 0 0;
        .question {
            align-items: center;
            border-top: solid 1px #EDEDED;
            display: flex;
            height: 173/750*100vw;
            position: relative;
            margin: 0;
            padding: 0 1em 0 240/750*100vw;

            &--01 {
                background: url(/assets/img/wwc__chef_img01.svg) no-repeat center left;
                background-size: 19vw;
            }
            &--02 {
                background: url(/assets/img/wwc__chef_img02.svg) no-repeat;
                background-position: 1vw center;
                background-size: 15vw;
            }
            &--03 {
                background: url(/assets/img/wwc__chef_img03.svg) no-repeat;
                background-position: 3vw center;
                background-size: 15vw;
            }
            &--04 {
                background: url(/assets/img/wwc__chef_img04.svg) no-repeat;
                background-position: 2vw center;
                background-size: 15vw;
            }
            &--05 {
                background: url(/assets/img/wwc__chef_img05.svg) no-repeat;
                background-position: 4vw center;
                background-size: 10vw;
            }
            &--06 {
                background: url(/assets/img/wwc__chef_img06.svg) no-repeat;
                background-position: 3vw center;
                background-size: 13vw;
            }
            &--07 {
                background: url(/assets/img/wwc__chef_img07.svg) no-repeat;
                background-position: 3vw center;
                background-size: 14vw;
            }
            &--08 {
                background: url(/assets/img/wwc__chef_img08.svg) no-repeat;
                background-position: 3vw center;
                background-size: 14vw;
            }
            p {
                @include vw(34);
            }
            .pointCardHead_icon {
                background: url(/assets/img/acord_icon.svg) no-repeat center center;
                background-size: contain;
                width: 5vw;
                height: 5vw;
                position: absolute;
                right: 0;
                top: calc(50% - 2.5vw);
                transition: .9s cubic-bezier(.19,1,.22,1);
            }
            &.is-opened .pointCardHead_icon {
                transform: rotate(135deg);
            }
        }
        .answer {
            display: none;
            padding: 0 1em 62/750*100vw 240/750*100vw;
            p {
                @include vw(26);
            }
        }
        ul {
            border-bottom: solid 1px #ededed;
        }
    }
}
.p-topWwc__contChefBg {
    position: absolute;
    opacity: 0;
    transform:scale(0.5,0.5);
    transition:0.6s all;
    &.contChefBg01 {
        display: block;
        top: 20px;
        left: -40px;
    }
    &.contChefBg02 {
        display: block;
        top: 20px;
        left: 170px;
    }
    &.contChefBg03 {
        display: block;
        top: 20px;
        left: 377px;
    }
    &.contChefBg04 {
        display: block;
        top: 20px;
        left: 589px;
    }
    &.contChefBg05 {
        display: block;
        top: 268px;
        left: 73px;
    }
    &.contChefBg06 {
        display: block;
        top: 268px;
        left: 285px;
    }
    &.contChefBg07 {
        display: block;
        top: 268px;
        left: 494px;
    }
    &.contChefBg08 {
        display: block;
        top: 268px;
        left: 699px;
    }
    &.isActive {
        opacity: 1;
        transform:scale(1,1);
    }
}
@keyframes big{
  0%{
    transform: scale(0);
  }
 100%{
    transform: scale(1);
  }
}
.p-topWwc__contChefList {
    display: flex;
    padding: 0 0 0 65px;
    @include sp {
        display: none;
    }
    &--02 {
        padding: 60px 0 0 180px;
    }
}
.p-topWwc__contChefListItem {
    cursor: pointer;
    width: 178px;
    &+.p-topWwc__contChefListItem {
        margin: 0 0 0 34px;
    }
    &--img {
        align-items: center;
        display: flex;
        height: 124px;
        justify-content: center;
        width: 178px;
    }
    &--text01 {
        align-items: center;
        display: flex;
        font-size: 14px;
        height: 70px;
        justify-content: center;
        width: 178px;
    }
    &--text02 {
        align-items: center;
        // display: flex;
        display: none;
        font-size: 13px;
        height: 70px;
        justify-content: center;
        width: 178px;
    }
}
.p-topWwc__contText {
    line-height: 40/16*1;
    margin: 58px 0 0;
    @include sp {
        margin: 88/750*100vw 0 0;
    }
    p {
        @include sp {
            line-height: 64/28*1;
            @include vw(28);
        }
        &+p {
            @include pc {
                margin: 46px 0 0;
            }
        }
    }
}
.p-topWwc__jpSubTtl {
    left: 0;
    position: absolute;
    @include sp {
        width: 100%;
    }
    &--about {
        opacity: 0;
        top: 0;
        transform: translateX(-140px);
        @include sp {
            left: -16vw;
            top: 4vw;
            transform: translateX(0);
        }
    }
    &--chef {
        margin: 0 0 103px;
        opacity: 0;
        top: 348px;
        transform: translateX(-140px);
        @include sp {
            left: -16vw;
            top: 6vw;
            transform: translateX(0);
        }
    }
    &--menber {
        opacity: 0;
        top: 0;
        transform: translateX(-140px);
        @include sp {
            left: -16vw;
            top: 6vw;
            transform: translateX(0);
        }
    }
    &--news {
        opacity: 0;
        left: 68px;
        top: 0;
        transform: translateX(-140px);
        @include sp {
            left: -16vw;
            transform: translateX(0);
        }
    }
}
.p-topWwc_ttl {
    opacity: 0;
    transform: translateX(-100px) scaleX(1.05);
}


.p-top__jpSubTtlLine {
    background: #000;
    height: 1px;
    position: absolute;
    width: 1000px;
    @include sp {
        width: 0%;
    }
    // width: 90%;
    &--about {
        left: -1020px;
        // left: -880px;
        opacity: 0;
        top: 15px;
        transform: translateX(-140px);
        @include sp {
            left: -5vw;
            top: 8vw;
            transform: translateX(0);
        }
    }
    &--chef {
        left: -1020px;
        opacity: 0;
        top: 365px;
        transform: translateX(-140px);
        @include sp {
            left: -5vw;
            top: 10vw;
            transform: translateX(0);
        }
    }
    &--menber {
        left: -1020px;
        // left: -880px;
        opacity: 0;
        top: 15px;
        transform: translateX(-140px);
        @include sp {
            left: -5vw;
            top: 10vw;
            transform: translateX(0);
        }
    }
    &--news {
        left: -812px;
        opacity: 0;
        top: 15px;
        transform: translateX(-240px);
        @include sp {
            left: -5vw;
            top: 5vw;
            transform: translateX(0);
            opacity: 1;
        }
    }
}


// .p-topWwc {
//     display: none;
// }