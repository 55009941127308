@charset "utf-8";
.l-header{
    left: 66px;
    position: absolute;
    top: 38px;
    z-index: 1;
    @include sp {
        left: 46/750*100vw;
        top: 38/750*100vw;
        width: 210/750*100vw;
    }
}
