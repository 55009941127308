
.m-listDist__item {
  color: #505050;
  font-family: $font-family-gothic;
  font-size: 14px;
  padding-left: 1em;
  text-indent: -1em;
  &::before {
    content: "・";
  }
}

