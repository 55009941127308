

.m-titleA {
  font-weight: bold;
  letter-spacing: 0.12em;
  text-align: center;
  @include pc {
    font-size: 28px;
    margin-bottom: 80px;
  }
  @include sp {
    margin-bottom: 50px;
    @include fs(23);
  }
}

.m-titleB {
  border-bottom: solid 1px #d6d6d6;
  font-weight: bold;
  @include pc {
    font-size: 20px;
    letter-spacing: 0.12em;
    margin-bottom: 25px;
    padding-bottom: 10px;
  }
  @include sp {
    letter-spacing: 0.15em;
    margin-bottom: 15px;
    padding-bottom: 10px;
    @include fs(17);
  }
  &:not(:first-child) {
    @include pc {
      margin-top: 40px;
    }
    @include sp {
      margin-top: 50px;
    }
  }
}
