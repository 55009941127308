@charset "utf-8";
.l-footer{
    @include pc {
        height: 74px;
    }
    #privacy & {
        border-top: solid 1px #D6D6D6;
    }
    #terms & {
        border-top: solid 1px #D6D6D6;
    }
}
.l-footer__inner {
    margin: 0 auto;
    padding: 45px 0 0;
    position: relative;
    text-align: center;
    width: 1100px;
    @include sp {
        padding: 220/750*100vw 0 44/750*100vw;
        width: 100%;
    }
    #privacy & {
        @include sp {
            padding: 64/750*100vw 0 44/750*100vw;
            width: 100%;
        }
    }
    #terms & {
        @include sp {
            padding: 64/750*100vw 0 44/750*100vw;
            width: 100%;
        }
    }
    p {
        color: #898989;
        font-size: 12px;
    }
}
.l-footer__list {
    display: flex;
    @include pc {
        left: 0;
        position: absolute;
        top: 45px;
    }
    @include sp {
        justify-content: center;
        margin: 0 0 63/750*100vw;
    }
}
.l-footer__listItem {
    line-height: 1.2;
    &+.l-footer__listItem {
        border-left: solid 1px #000;
        margin: 0 0 0 10px;
        padding: 0 0 0 10px;
    }
    a {
        font-family: $font-family-gothic;
        font-size: 13px;
        @include sp {
            @include vw(22);
        }
    }
}