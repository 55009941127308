
.m-txt {
  color: #505050;
  font-family: $font-family-gothic;
  @include pc {
    letter-spacing: 0.1em;
    line-height: 1.85;
    font-size: 14px;
  }
  @include sp {
    letter-spacing: 0.08em;
    line-height: 1.92;
    @include fs(13);
  }
  &--gray {
    color: #505050;
  }
}

.m-txtOutline {
  letter-spacing: 0.08em;
  @include pc {
    font-size: 16px;
    line-height: 2.25;
    margin-bottom: 100px;
  }
  @include sp {
    line-height: 1.92;
    margin-bottom: 65px;
    @include fs(14);
  }
}
