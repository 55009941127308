.m-gototop {
    bottom: 30px;
    color: #000;
    display: none;
    position: fixed;
    right: 50px;
    // right: 80px;
    z-index: 2;
    @include sp {
        // bottom: 100px;
        right: 30/750*100vw;
    }
    &:after {
        animation: sdl2 2s cubic-bezier(1, 0, 0, 1) infinite;
        background: #000;
        bottom: 60px;
        content: '';
        height: 100px;
        left: 50%;
        position: absolute;
        transform: rotate(180deg);
        width: 1px;
        @include sp {
            // bottom: -80px;
            height: 70px;
        }
    }
    @keyframes sdl2 {
        0% {
            transform: scale(1, 0);
            transform-origin: 0 100%;
        }
        50% {
            transform: scale(1, 1);
            transform-origin: 0 100%;
        }
        50.1% {
            transform: scale(1, 1);
            transform-origin: 0 0;
        }
        100% {
            transform: scale(1, 0);
            transform-origin: 0 0;
        }
 }
}
